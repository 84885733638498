//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { LocalStorage } from "quasar";
export default {
  name: "IndexSale",
  props: ["_stores", "_currentStore", "_startDate", "_endDate","_brandCode"],
  data() {
    return {
      chartIns: {
        saleTrend: null,
      },
      stores: this._stores,
      currentStore: this._currentStore,
      startDate: this._startDate,
      endDate: this._endDate,
      brandCode:this._brandCode,
      apis: {},
      basicData: {},
      structure: {
        newAndOld: {
          newNum: 0,
          oldNum: 0,
          newMoney: 0,
          oldMoney: 0,
          numP: 0,
          moneyP: 0,
        },
        birthAndNot: {},
      },
      levelData: {},
      categoryData: [],
      categoryDataMore: [],
      thisNumSort: [],
      thisNumSortMore: [],
      thisMoneySort: [],
      thisMoneySortMore: [],
      allNumSort: [],
      allNumSortMore: [],
      allMoneySort: [],
      allMoneySortMore: [],
      allSortBy: "allNum",
      thisSortBy: "thisNum",

      saleLoading: false,
      vipSaleLoading: false,
      categorySaleLoading: false,
      allModelLoading: false,
      thisModelLoading: false,
      saleTrendLoading: false,

      hasMoreCategory: false,
      categoryDataMoreOpen: false,
      allNumSortMoreOpen: false,
      allMoneySortMoreOpen: false,
      thisNumSortMoreOpen: false,
      thisMoneySortMoreOpen: false,
      kdgrp: LocalStorage.getItem("kdgrp"),
      salesStructureAnalysisData: {
        tagPrice: 0,
        discountPrice: 0,
        memberPrice: 0,
        VOL_NewMemberOrder: 0,
        VOL_OldMemberOrder: 0,
        RATE_Related: 0,
        VOL_NotMemberActualSalesOrder:0,
        goodsPrice:0,
        RATE_OldMemberOrderNum:0

      }
    };
  },
  created() {
    const that = this;
    if (this.stores.length > 0) {
      this.getAPI(function () {
        that.dispatchApi();
      });
    }
  },
  activated() {
    window.document.title = "门店业绩";
    if (this.drawSaleTrend && this.trendData) {
      this.drawSaleTrend(
        this.trendData.xData,
        this.trendData.zhehouData,
        this.trendData.diaopaiData,
        this.trendData.shishouData
      );
    }
  },
  methods: {
    drawLine(el, option) {
      console.log(option);
      this.chartIns[el] = echarts.init(document.getElementById(el));
      this.chartIns[el].setOption(option);
    },
    drawSaleTrend(xData, zhehouData, diaopaiData, shishouData) {
      let option = {
        title: {
          text: "",
          top: 10,
        },
        color: ["#4f79d3", "#67ba7d", "#ffd143"],
        legend: {
          data: ["折后金额", "吊牌金额", "实收金额"],
        },
        grid: {
          left: "15%",
          top: 30,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLabel: {
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: zhehouData,
            type: "line",
            name: "折后金额",
          },
          {
            data: diaopaiData,
            type: "line",
            name: "吊牌金额",
          },
          {
            data: shishouData,
            type: "line",
            name: "实收金额",
          },
        ],
      };

      this.drawLine("saleTrend", option);
    },
    gotoSameTimeData() {
      this.$router.push(
        "/storeanalysis/lastsale?startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&store=" +
          this.currentStore
      );
    },
    updateParams(store, start, end,brand) {
      this.currentStore = store;
      this.startDate = start;
      this.endDate = end;
      if(brand){this.brandCode=brand};
    },
    dispatchApi() {
      console.log(this.startDate, this.endDate);

      this.getShopSale();
      this.getVipSaleData();
      this.getCategorySaleData();
      this.getSaleTrend();
      this.getAllModelTop();
      this.getThisModelTop();
     // this.getNewOldCustomerSale();
      this.getSalesStructureAnalysisData();
    },
    getNewOldCustomerSale() {
      // const that = this;
      // let startDate = this.startDate;
      this.newOldLoading = true;
      let endDate = this.endDate;
      const data = [
        {
          HIGH: "",
          LOW: endDate.slice(0, 6),
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0CALMONTH_MVSM_001",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.brandCode,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVMO_001",
          SIGN: "I",
        },
      ];
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/newOldVIPStructure",
          data
        )
        .then((res) => {
          console.log("新老客数据", res);
          this.newOldLoading = false;
          if (res && res.data && res.data.data) {
            const temp = res.data.data[0];
            this.newOldCustoerData = {
              newCount: Number(temp.newCustomerCount),
              oldCount: Number(temp.oldCustomerCount),
              newMoney: parseInt(temp.newCustomerSale),
              oldMoney: parseInt(temp.oldCustomerSale),
              numP: parseInt(
                (Number(temp.newCustomerCount) /
                  (Number(temp.newCustomerCount) +
                    Number(temp.oldCustomerCount))) *
                  100
              ),
              moneyP: parseInt(
                (parseInt(temp.newCustomerSale) /
                  (parseInt(temp.newCustomerSale) +
                    parseInt(temp.oldCustomerSale))) *
                  100
              ),
            };
          } else {
            this.newOldCustoerData = {
              newCount: 0,
              oldCount: 0,
              newMoney: 0,
              oldMoney: 0,
              numP: 0,
              moneyP: 0,
            };
          }
        });
    },
    getAPI(callback) {
      const that = this;
      // this.axios.post('/v1/bw/BwApi/getFunList', {
      this.axios
        .post("/" + this.$ajaxPrefix.consumer + "/bw/BwApi/getFunList", {
          queryMap: {
            q_status: 1,
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            temp.forEach((item) => {
              that.apis[item.outIdentity] = item.outIdentity; //.split('_').join('/');
            });
            callback && callback();
          }
        });
    },
    getSalesStructureAnalysisData() {
      this.salesStructureAnalysisData={
        tagPrice: 0,
        discountPrice: 0,
        memberPrice: 0,
        VOL_NewMemberOrder: 0,
        VOL_OldMemberOrder: 0,
        RATE_Related: 0,
      }
      let startDate = this.$moment(this.startDate).format("yyyyMM");
      console.log('getSalesStructureAnalysisData',data)
      const data = [
        {
          HIGH: "",
          LOW: startDate,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0CALMONTH_MVSM_001",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.brandCode,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0INDUSTRY_MVMM_003",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVMO_001",
          SIGN: "I",
        },
      ];
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/getSalesStructureAnalysis",
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            let tempData = res.data.data;
            this.$storage.set('salesStructureAnalysisData',tempData)
            for (let index = 0; index < tempData.length; index++) {
              var element = tempData[index];
              if(element.structureName=='当期'){
              this.salesStructureAnalysisData.tagPrice=element.tagPrice;
              this.salesStructureAnalysisData.discountPrice=element.discountPrice;
              this.salesStructureAnalysisData.memberPrice=element.memberPrice;
              this.salesStructureAnalysisData.VOL_NewMemberOrder=element.VOL_NewMemberOrder;
              this.salesStructureAnalysisData.VOL_OldMemberOrder=element.VOL_OldMemberOrder;
              console.log(' this.salesStructureAnalysisData.VOL_OldMemberOrder', this.salesStructureAnalysisData.VOL_OldMemberOrder)
              this.salesStructureAnalysisData.RATE_Related=element.RATE_Related;
              this.salesStructureAnalysisData.VOL_NotMemberActualSalesOrder=element.VOL_NotMemberActualSalesOrder;
              this.salesStructureAnalysisData.goodsPrice=element.goodsPrice;
              this.salesStructureAnalysisData.RATE_OldMemberOrderNum=element.RATE_OldMemberOrderNum;
              }

            }
            console.log("getSalesStructureAnalysis", tempData);
          }
        });
    },
    getShopSale() {
      const that = this;
      let startDate = this.startDate;
      let endDate = this.endDate;
      const data = [
        {
          HIGH: endDate,
          LOW: startDate,
          LOW_FLAG: "1",
          OPTION: "CP",
          PARAMETER: "ZDATE_103",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "1",
          OPTION: "EQ",
          PARAMETER: "ZV_MD06",
          SIGN: "I",
        },
      ];
      this.saleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['saleAnalysis_getSales'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            this.apis["saleAnalysis_getSales"],
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            that.basicData = res.data.data[0];

            if (that.basicData) {
              let newMoney = Number(
                  Number(that.basicData.newVipSaleMoney).toFixed(0)
                ),
                oldMoney = Number(
                  Number(that.basicData.oldVipSaleMoney).toFixed(0)
                ),
                newNum = Number(
                  Number(that.basicData.newVipSaleNum).toFixed(0)
                ),
                oldNum = Number(
                  Number(that.basicData.oldVipSaleNum).toFixed(0)
                ),
                allVipNum = newNum + oldNum,
                birthVipNum = Number(
                  Number(that.basicData.birthVipShopCount).toFixed(0)
                ),
                notBirthVipNum = allVipNum - birthVipNum,
                allVipMoney = newMoney + oldMoney,
                birthVipMoney = Number(
                  Number(that.basicData.birthVipShopMoney).toFixed(0)
                ),
                notBirthVipMoney = allVipMoney - birthVipMoney;
              that.structure.newAndOld = {
                newMoney,
                oldMoney,
                newNum,
                oldNum,
                numP: parseInt((newNum / (newNum + oldNum)) * 100),
                moneyP: parseInt((newMoney / (newMoney + oldMoney)) * 100),
              };
              that.structure.birthAndNot = {
                birthVipMoney,
                notBirthVipMoney,
                birthVipNum,
                notBirthVipNum,
                numP: parseInt(
                  (birthVipNum / (birthVipNum + notBirthVipNum)) * 100
                ),
                moneyP: parseInt(
                  (birthVipMoney / (birthVipMoney + notBirthVipMoney)) * 100
                ),
              };
            } else {
              that.structure.newAndOld = {
                newNum: 0,
                oldNum: 0,
                newMoney: 0,
                oldMoney: 0,
                numP: 0,
                moneyP: 0,
              };
              that.structure.birthAndNot = {
                birthVipMoney: 0,
                notBirthVipMoney: 0,
                birthVipNum: 0,
                notBirthVipNum: 0,
                numP: 0,
                moneyP: 0,
              };
            }
          } else {
            that.structure.newAndOld = {
              newNum: 0,
              oldNum: 0,
              newMoney: 0,
              oldMoney: 0,
              numP: 0,
              moneyP: 0,
            };
            that.structure.birthAndNot = {
              birthVipMoney: 0,
              notBirthVipMoney: 0,
              birthVipNum: 0,
              notBirthVipNum: 0,
              numP: 0,
              moneyP: 0,
            };
          }
          that.saleLoading = false;
        });
    },
    getVipSaleData() {
      const that = this;

      const data = [
        {
          HIGH: this.endDate,
          LOW: this.startDate,
          LOW_FLAG: "1",
          OPTION: "BT",
          PARAMETER: "ZDATE_001",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "0",
          OPTION: "EQ",
          PARAMETER: "ZV_KH003",
          SIGN: "I",
        },
      ];
      this.vipSaleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['sales_structure'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            this.apis["sales_structure"],
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            const levels = new Set();
            const levelData = {};
            let moneySum = 0;
            temp.forEach((item) => {
              levels.add(item.level);
              moneySum += Number(item.vipMoney);
            });
            let levelArr = Array.from(levels);
            levelArr.forEach((item) => {
              levelData[item] = {
                money: 0,
                peopleCount: 0,
              };
            });
            for (let level in levelData) {
              temp.forEach((item) => {
                if (item.level === level) {
                  levelData[level]["money"] += Number(item.vipMoney);
                  levelData[level]["peopleCount"] += 1;
                }
              });
            }
            for (let level in levelData) {
              levelData[level]["percent"] = Number(
                (levelData[level]["money"] / moneySum) * 100
              ).toFixed(2);
            }
            that.levelData = levelData;
          } else {
            that.levelData = {};
          }
          that.vipSaleLoading = false;
        });
    },
    getSaleTrend() {
      const that = this;
      const data = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZV_KH003",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "0",
        },
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "ZDATE_103",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
      ];
      this.saleTrendLoading = true;

      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/bw/BwApi/" + "storeSaleTrend",
          data
        )
        .then((res) => {
          this.saleTrendLoading = false;
          let xData = [],
            zhehouData = [],
            diaopaiData = [],
            shishouData = [];
          if (res && res.data && res.data.data) {
            console.log("销售趋势", res);
            const temp = res.data.data;
            temp.forEach((item) => {
              if (item.date != "SUMME") {
                xData.push(
                  item.date.substr(0, 4) +
                    "-" +
                    item.date.substr(4, 2) +
                    "-" +
                    item.date.substr(6, 2)
                );
                zhehouData.push(parseInt(item.zhehouMoney));
                diaopaiData.push(parseInt(item.diaopaiMoney));
                shishouData.push(parseInt(item.shishouMoney));
              }
            });
          }
          this.trendData = {
            xData,
            zhehouData,
            diaopaiData,
            shishouData,
          };
          this.$nextTick(function () {
            setTimeout(function () {
              that.drawSaleTrend(xData, zhehouData, diaopaiData, shishouData);
            }, 500);
          });
        });
    },

    getCategorySaleData() {
      const that = this;
      const data = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZV_MD06",
          LOW: this.currentStore,
          HIGH: "",
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "ZDATE_001",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
        // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"10","HIGH":"","LOW_FLAG":"1"}
      ];
      this.categorySaleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['QueryReport_queryCategorySalesProportion'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            this.apis["QueryReport_queryCategorySalesProportion"],
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            let cates = new Set();
            const _temp = [];
            temp.forEach((item) => {
              if (item.category != "SUMME") {
                cates.add(item.category);
                _temp.push(item);
              }
            });
            let catesArr = Array.from(cates);
            const cateData = {};
            catesArr.forEach((item) => {
              cateData[item] = {
                name: "",
                quantity: 0,
                quantityP: 0,
                disAmount: 0,
                disP: 0,
                paidAmount: 0,
                paidP: 0,
              };
            });
            for (let key in cateData) {
              _temp.forEach((item) => {
                if (key === item.category) {
                  cateData[key]["name"] = item.categoryName;
                  cateData[key]["quantity"] += Number(item.quantity);
                  cateData[key]["quantityP"] += Number(item.quantityProportion);
                  cateData[key]["disAmount"] += Number(item.discountedAmount);
                  cateData[key]["disP"] += Number(
                    item.discountedAmountProportion
                  );
                  cateData[key]["paidAmount"] += Number(item.paidAmount);
                  cateData[key]["paidP"] += Number(item.paidAmountProportion);
                }
              });
            }
            let keys = Object.keys(cateData);
            let list = [];
            keys.forEach((item) => {
              list.push(cateData[item]);
            });
            console.log(list);
            list.sort((a, b) => {
              return b.disAmount - a.disAmount;
            });
            this.categoryData = list;
            LocalStorage.set("categoryCata", JSON.stringify(this.categoryData));
          } else {
            that.categoryData = [];
            that.categoryDataMore = [];
          }
          that.categorySaleLoading = false;
        });
    },

    getAllModelTop() {
      const that = this;
      const data = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZVAR_SALETYPE01",
          LOW: "P",
          HIGH: "",
        },
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "ZDATE_001",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZPP_003",
          LOW: "10",
          HIGH: "",
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZPP_003",
          LOW: "12",
          HIGH: "",
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZPP_003",
          LOW: "13",
          HIGH: "",
          LOW_FLAG: "1",
        },
      ];
      this.allModelLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            this.apis["top10_getModelsTop10"],
          data
        )
        .then((res) => {
          //VOL-YD 销量    VAL-YD 销额
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            temp.sort((a, b) => {
              return Number(b.VOL_YD) - Number(a.VOL_YD);
            });
            let allNumSort = temp.length > 50 ? temp.slice(0, 50) : temp;
            temp.sort((a, b) => {
              return Number(b.VAL_YD) - Number(a.VAL_YD);
            });
            let allMoneySort = temp.length > 50 ? temp.slice(0, 50) : temp;
            this.allNumSort = allNumSort;
            this.allMoneySort = allMoneySort;
            LocalStorage.set("allNumSort", JSON.stringify(allNumSort));
            LocalStorage.set("allMoneySort", JSON.stringify(allMoneySort));
          } else {
            that.allMoneySort = [];

            that.allNumSort = [];
          }
          that.allModelLoading = false;
        });
    },
    getThisModelTop() {
      const that = this;
      const data = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZVAR_SALETYPE01",
          LOW: "P",
          HIGH: "",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZV_KH001",
          LOW: this.currentStore,
          HIGH: "",
        },
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "ZDATE_001",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
        // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZV_CPNF07","LOW":this.$moment(this.startDate).format('yyyy'),"HIGH":"","LOW_FLAG":"1"}
      ];
      this.thisModelLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['top10_getModelsTop10'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            this.apis["top10_getModelsTop10"],
          data
        )
        .then((res) => {
          //VOL-YD 销量    VAL-YD 销额
          if (res && res.data && res.data.data) {
            let temp = res.data.data;
            temp.sort((a, b) => {
              return Number(b.VOL_YD) - Number(a.VOL_YD);
            });
            let thisNumSort = temp.length > 50 ? temp.slice(0, 50) : temp;
            temp.sort((a, b) => {
              return Number(b.VAL_YD) - Number(a.VAL_YD);
            });
            let thisMoneySort = temp.length > 50 ? temp.slice(0, 50) : temp;
            this.thisNumSort = thisNumSort;
            this.thisMoneySort = thisMoneySort;
            LocalStorage.set("thisNumSort", JSON.stringify(thisNumSort));
            LocalStorage.set("thisMoneySort", JSON.stringify(thisMoneySort));
          } else {
            that.thisMoneySort = [];
            that.thisMoneySortMore = [];
            that.thisNumSort = [];
            that.thisNumSortMore = [];
          }
          that.thisModelLoading = false;
        });
    },
    showMoreCategoryData() {
      this.$router.push("/storeanalysis/moredata?seg=category");
    },
    showMoreThisSort() {
      this.$router.push("/storeanalysis/moredata?seg=thisTop");
    },
    showMoreAllSort() {
      this.$router.push("/storeanalysis/moredata?seg=allTop");
    },
  },
};
